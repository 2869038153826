exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-bio-tsx": () => import("./../../../src/pages/bio.tsx" /* webpackChunkName: "component---src-pages-bio-tsx" */),
  "component---src-pages-exhibitions-tsx": () => import("./../../../src/pages/exhibitions.tsx" /* webpackChunkName: "component---src-pages-exhibitions-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-work-drawings-tsx": () => import("./../../../src/pages/work/drawings.tsx" /* webpackChunkName: "component---src-pages-work-drawings-tsx" */),
  "component---src-pages-work-films-tsx": () => import("./../../../src/pages/work/films.tsx" /* webpackChunkName: "component---src-pages-work-films-tsx" */),
  "component---src-pages-work-index-tsx": () => import("./../../../src/pages/work/index.tsx" /* webpackChunkName: "component---src-pages-work-index-tsx" */),
  "component---src-pages-work-oil-paintings-tsx": () => import("./../../../src/pages/work/oil-paintings.tsx" /* webpackChunkName: "component---src-pages-work-oil-paintings-tsx" */)
}

